<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: "configuration_management",
    data() {
        return {

        }
    }
}
</script>
